<script>
import Vue from "vue";
import firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAVoPK2FpE6cOVshGuiYg58Glhz8XLyG4I",
  authDomain: "rmobility2020-46bea.firebaseapp.com",
  projectId: "rmobility2020-46bea",
  databaseURL: "https://rmobility2020-46bea.firebaseio.com",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

// Required for side-effects
require("firebase/firestore");

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Notification",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Notification",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Notification",
          active: true,
        },
      ],
      db: firebaseApp.firestore(),
      notifdashseen: 0,
      notifdashunseen: 0,
      notifseen: 0,
      notifunseen: 0,
      allseendash: true,
      allseen: true,
      typenotif: [
        {
          name: "Bienvenue",
          value: "welcome_notification",
        },
        {
          name: "Envoyé par le dash",
          value: "system_notification",
        },
        {
          name: "Profil rejeté",
          value: "image_profil_rejected",
        },
        {
          name: "Permis rejeté",
          value: "permis_rejected",
        },
        {
          name: "CNI rejeté",
          value: "cni_rejected",
        },
        {
          name: "Automoto rejeté",
          value: "auto_rejected",
        },
        {
          name: "Profil validé",
          value: "image_profil_validated",
        },
        {
          name: "CNI validé",
          value: "cni_validated",
        },
        {
          name: "Permis validé",
          value: "permis_validated",
        },
        {
          name: "Automoto validé",
          value: "auto_validated",
        },
        {
          name: "Alerte trajet trouvé",
          value: "alert_travel_found",
        },
        {
          name: "Rappel passager",
          value: "trajet_start_passager",
        },
        {
          name: "Rappel conducteur",
          value: "trajet_start_conducteur",
        },
        {
          name: "Réservation passager",
          value: "reservation_confirmed_passager",
        },
        {
          name: "Réservation conducteur",
          value: "reservation_confirmed_conducteur",
        },
        {
          name: "Trajet supprimé",
          value: "trajet_deleted",
        },
        {
          name: "Demande de réservation",
          value: "reservation_request_sent",
        },
        {
          name: "Réservation accepté",
          value: "reservation_request_accepted",
        },
        {
          name: "Réservation rejeté",
          value: "reservation_request_rejected",
        },
        {
          name: "Annulation passager",
          value: "reservation_annulated_by_passager",
        },
        {
          name: "Annulation conducteur",
          value: "reservation_annulated_by_conducteur",
        },
      ],
      typenotifdash: [
        {
          name: "Pièces",
          value: 0,
        },
        {
          name: "Avis",
          value: 1,
        },
        {
          name: "Urgence",
          value: 2,
        },
        {
          name: "Annif",
          value: 3,
        },
      ],
      value: null,
      valuedash: null,
      order: [],
      ordersData: [],
      showAll: false,
      users: [],
      rowCall: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "conducteur", sortable: true, label: "Conducteur" },
        { key: "place", sortable: true, label: "Places" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "total", sortable: true, label: "Montant" },
        { key: "action" },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
    };
  },

  async mounted() {
    //Chargement des données
    this.init();
  },

  methods: {
    async init() {
      const snapshotdashunseen = await this.db
        .collection("notifdash")
        .where("seen", "==", false)
        .get();
      //.orderBy("dateNotif", "desc")
      this.notifdashunseen = snapshotdashunseen.size;

      const snapshotdashseen = await this.db
        .collection("notifdash")
        .where("seen", "==", true)
        .get();
      this.notifdashseen = snapshotdashseen.size;

      const snapshotunseen = await this.db
        .collection("notifications")
        .where("seen", "==", false)
        .limit(1000)
        .get();
      this.notifunseen = snapshotunseen.size;

      const snapshotseen = await this.db
        .collection("notifications")
        .where("seen", "==", true)
        .get();
      this.notifseen = snapshotseen.size;

       },
   
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      //this.create();
    },
    async deleteNotifUser() {


      if (this.value !== null) {
        await this.db
          .collection("notifications")
          .where("seen", "==", this.allseen)
          .where("type", "==", this.value.value)
          .limit(3000)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
              var id = doc.id;
              //var data = doc.data();
  

              await this.db
                .collection("notifications")
                .doc(id)
                .delete();
             
            });
          });

        this.position();
      }
    },
    async deleteNotifDash() {
 

      if (this.valuedash !== null) {
        await this.db
          .collection("notifdash")
          .where("seen", "==", this.allseendash)
          .where("typeNotif", "==", this.valuedash.value)
          //.limit(3)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
              var id = doc.id;    
              await this.db
                .collection("notifdash")
                .doc(id)
                .delete();
            });
          });
      }
    },
    async deleteNotifDashAll() {
      await this.db
        .collection("notifdash")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async (doc) => {
            var id = doc.id;
            //var data = doc.data();
            await this.db
              .collection("notifdash")
              .doc(id)
              .delete();
          });
        });
        this.position();
    },
    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    async search() {
      if (this.dateJournal != null) {
        const data = await apiRequest(
          "GET",
          "admin/journal?all=" + false + "&date=" + this.dateJournal,
          undefined,
          false
        );
        if (data && data.data) {
          const formattedTable = data.data.map((journal) => {
            return {
              admin: journal.admin.lastname + " " + journal.admin.firstname,
              details: journal.text,
              date: journal.dateHeure,
            };
          });
          this.activityData = formattedTable;
        }
      }
    },
    async all() {
      const data = await apiRequest(
        "GET",
        "admin/journal?all=" + true + "&date=" + this.dateJournal,
        undefined,
        false
      );
      if (data && data.data) {

        const formattedTable = data.data.map((journal) => {
          return {
            admin: journal.admin.lastname + " " + journal.admin.firstname,
            details: journal.text,
            date: journal.dateHeure,
          };
        });
        this.activityData = formattedTable;
      }
    },
    position() {
      Vue.swal({
        position: "center",
        icon: "success",
        title: "Effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
  },

  
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5>Notification utilisateurs</h5>
              <h6 class="mt-2">
                Vu : {{ notifseen }} - Non vu : {{ notifunseen }} +
              </h6>
              <div class="form-group mt-4">
                <div class="row">
                  <div class="col-md-2">
                    <label class="control-label">Type</label>
                  </div>
                  <div class="col-md-5">
                    <multiselect
                      v-model="value"
                      :options="typenotif"
                      label="name"
                      track-by="name"
                    ></multiselect>
                  </div>
                  <div class="col-md-3">
                    <input
                      type="checkbox"
                      v-model="allseen"
                      class="mr-2"
                      checked=""
                    />
                    <b style="color:red">Déjà vu</b>
                  </div>
                </div>
                <div class="mt-4">
                  <button
                    class="btn btn-primary float-right"
                    v-on:click="deleteNotifUser()"
                  >
                    Supprimer la sélection
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5>Notification dashboard</h5>
              <h6 class="mt-2">
                Traité : {{ notifdashseen }} - Non traité :
                {{ notifdashunseen }}
              </h6>
              <div class="form-group mt-4">
                <div class="row">
                  <div class="col-md-2">
                    <label class="control-label">Type</label>
                  </div>
                  <div class="col-md-5">
                    <multiselect
                      v-model="valuedash"
                      :options="typenotifdash"
                      label="name"
                      track-by="name"
                    ></multiselect>
                  </div>
                  <div class="col-md-3">
                    <input type="checkbox" v-model="allseendash" class="mr-2" />
                    <b style="color:red">Déjà traité</b>
                  </div>
                </div>
                <div class="mt-4">
                  <button
                    class="btn btn-danger float-left"
                    v-on:click="deleteNotifDashAll()"
                  >
                    Tout supprimer
                  </button>
                  <button
                    class="btn btn-primary float-right"
                    v-on:click="deleteNotifDash()"
                  >
                    Supprimer la sélection
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showdisable"
      title="Voulez-vous vraiment suspendre cet utilisateur ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactiveUser"
          >Oui</b-button
        >
      </div>
    </b-modal>
    <b-modal
      id="modal-xl"
      size="xl"
      v-model="showAll"
      title="Extra large modal"
      title-class="font-18"
      hide-footer
    >
      <b-tabs nav-class="nav-tabs-custom">
        <b-tab title-link-class="p-3">
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Afficher&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entrées
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Rechercher:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive">
            <b-table
              class="table-centered"
              :items="activityData"
              :fields="activity"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(paymentstatus)="row">
                <div
                  class="badge font-size-12"
                  :class="{
                    'badge-soft-danger': `${row.value}` === 'Chargeback',
                    'badge-soft-success': `${row.value}` === 'Paid',
                    'badge-soft-warning': `${row.value}` === 'Unpaid',
                  }"
                >
                  {{ row.value }}
                </div>
              </template>
              <template v-slot:cell(invoice)>
                <button class="btn btn-light btn-rounded">
                  Invoice
                  <i class="mdi mdi-download ml-2"></i>
                </button>
              </template>
              <template v-slot:cell(action)>
                <a
                  href="javascript:void(0);"
                  class="btn btn-success mr-3"
                  v-b-tooltip.hover
                  title="virement"
                >
                  Virement
                </a>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
  </Layout>
</template>

<style></style>
